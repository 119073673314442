const mixin = {
  data() {
    return {
      //HOST: "http://68.178.168.214:8888",
      //SECURITY: "http://68.178.168.214:8888",
      HOST: "https://langolview.xyz",
      SECURITY: "https://langolview.xyz",
      PROPERTY: "Langol View Laboratory ",
      OAUTH_CLIENT_ID: "smclient",
      OAUTH_CLIENT_SECRET: "smclient00ll",
      OAUTH_SCOPE: "openid",
      OAUTH_GRANT_TYPE: "password",
      proxy: {
        host: "langolview.xyz",
        port: 8888,
      },
      // proxy: {
      //   host: "localhost",
      //   port: 8080,
      // },
      REPORT_AVAILABLE: "Active",
      REPORT_UNAVAILABLE: "Inactive",
      expandableHeaderBackground: "#1E88E5",
    };
  },
  created: function() {},
  methods: {},
};
export default mixin;
